import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import type DataSourceSummaryForHome from '../../types/DataSourceSummaryForHome';
import type ModuleSummaryForHome from '../../types/ModuleSummaryForHome';

import {
    Breakpoint,
    Chevron,
    ChevronDirection,
    H4,
    LayoutGridElements,
    Spacer,
    widthByLayoutGridColumns,
} from '@tonkean/infrastructure';
import type { ExampleSolutionSummary, PageSummaryForHome } from '@tonkean/marketplace-item-view';
import { DatasourceBox } from '@tonkean/marketplace-item-view';
import { PreviewModuleMarketplaceItemBox } from '@tonkean/marketplace-item-view';
import { ExampleSolutionBox } from '@tonkean/marketplace-item-view';
import { MarketplaceHomeCategories } from '@tonkean/marketplace-item-view';
import { PreviewPageMarketplaceItemBox } from '@tonkean/marketplace-item-view';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';
import { ClickableLink, LinkUnderline } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const SolutionBox = styled(ExampleSolutionBox)<{ wideMode: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ wideMode }) =>
        wideMode
            ? css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 5;
                  }
              `
            : css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 3;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
                  }
              `};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    ${widthByLayoutGridColumns};
`;

const ModuleBox = styled(PreviewModuleMarketplaceItemBox)<{ wideMode: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ wideMode }) =>
        wideMode
            ? css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 5;
                  }
              `
            : css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 3;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
                  }
              `};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    ${widthByLayoutGridColumns};
`;

const DatasourceBoxStyled = styled(DatasourceBox)<{ wideMode: boolean }>`
    height: 72px;

    ${({ wideMode }) =>
        wideMode
            ? css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 5;
                  }
              `
            : css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
                  }
              `};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    ${widthByLayoutGridColumns};
`;

const PageBox = styled(PreviewPageMarketplaceItemBox)<{ wideMode: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ wideMode }) =>
        wideMode
            ? css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 5;
                  }
              `
            : css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 3;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
                  }
              `};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    ${widthByLayoutGridColumns};
`;

const CategoryWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 16px 0;
    gap: 24px;

    grid-template-columns: repeat(auto-fill, auto);
`;

const ItemTypeHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

const AllLink = styled(ClickableLink)`
    display: flex !important;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;

    &,
    &:focus,
    &:hover {
        color: ${Theme.colors.primaryHighlight};
    }
`;

const LinkChevron = styled(Chevron)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    background-color: ${Theme.colors.gray_300};
    border-radius: 50%;

    svg > path {
        stroke: ${Theme.colors.primaryHighlight};
    }
`;

interface Props {
    discoverMode?: boolean;
    hideGroupsHeaders?: boolean;
    solutionSummaries: ExampleSolutionSummary[];
    onCategoryChanged: (value: string) => void;
    wideMode?: boolean;
    dataSourceSummaries: DataSourceSummaryForHome[];
    moduleSummaries: ModuleSummaryForHome[];
    pageSummaries: PageSummaryForHome[];
}

const MarketplaceItemsDiscoveryGrid: React.FC<Props> = ({
    discoverMode = false,
    hideGroupsHeaders = false,
    solutionSummaries,
    dataSourceSummaries,
    moduleSummaries,
    pageSummaries,
    wideMode = false,
    onCategoryChanged,
}) => {
    const discoverDataSources = useMemo(() => {
        return ['salesforce', 'slack_app', 'emailwebhook', 'jira', 'ironclad', 'workday'];
    }, []);

    const discoverModules = useMemo(() => {
        return [
            'generate-onboarding-documents',
            'compare-with-existing-vendors',
            'global-faq-q-a',
            'legal-request-from-email',
        ];
    }, []);

    const itemMapByType = useMemo(() => {
        const map: {
            [MarketplaceItemType.SOLUTION]: ExampleSolutionSummary[];
            [MarketplaceItemType.MODULE]: ModuleSummaryForHome[];
            [MarketplaceItemType.DATA_SOURCE]: DataSourceSummaryForHome[];
            [MarketplaceItemType.PAGE]: PageSummaryForHome[];
        } = {
            [MarketplaceItemType.SOLUTION]: solutionSummaries
                .filter((solution) => (discoverMode ? solution.showOnDiscovery : true))
                .sort((solutionA, solutionB) => {
                    // if showOnDiscovery=true - show on top, otherwise - show by updated
                    if (solutionA.showOnDiscovery !== solutionB.showOnDiscovery) {
                        const solutionBDiscovry = solutionB.showOnDiscovery ? 1 : 0;
                        const solutionADiscovry = solutionA.showOnDiscovery ? 1 : 0;
                        return solutionBDiscovry - solutionADiscovry;
                    }
                    return solutionB.updated - solutionA.updated;
                })
                .slice(0, discoverMode ? 4 : undefined),
            [MarketplaceItemType.MODULE]: moduleSummaries
                .sort((moduleA, moduleB) => moduleB.updated - moduleA.updated)
                .filter((module) => (discoverMode ? discoverModules.includes(module.templateName) : true)),
            [MarketplaceItemType.DATA_SOURCE]: dataSourceSummaries
                .sort((dataSourceA, dataSourceB) => {
                    // sort data sources by title alphabetically
                    if (dataSourceA.title < dataSourceB.title) {
                        return -1;
                    }
                    if (dataSourceA.title > dataSourceB.title) {
                        return 1;
                    }
                    return 0;
                })
                .filter((dataSource) => (discoverMode ? discoverDataSources.includes(dataSource.templateName) : true)),
            [MarketplaceItemType.PAGE]: pageSummaries
                .sort((pageA, pageB) => pageB.updated - pageA.updated)
                .slice(0, discoverMode ? 4 : undefined),
        };

        return map;
    }, [
        dataSourceSummaries,
        discoverDataSources,
        discoverMode,
        discoverModules,
        moduleSummaries,
        pageSummaries,
        solutionSummaries,
    ]);

    return (
        <>
            {itemMapByType[MarketplaceItemType.SOLUTION].length > 0 && (
                <>
                    {!hideGroupsHeaders && (
                        <ItemTypeHeader>
                            <H4>{`${discoverMode ? 'Featured ' : ''}${utils.capitalize(
                                MarketplaceItemType.SOLUTION.toLowerCase(),
                            )}s`}</H4>

                            {discoverMode && (
                                <AllLink
                                    onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS)}
                                    underline={LinkUnderline.ALWAYS}
                                    state="/"
                                    params={{ category: MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS }}
                                    replace
                                >
                                    {`All ${utils.capitalize(MarketplaceItemType.SOLUTION.toLowerCase())}s`}
                                    <Spacer width={10} />
                                    <LinkChevron direction={ChevronDirection.RIGHT} />
                                </AllLink>
                            )}
                        </ItemTypeHeader>
                    )}
                    <CategoryWrapper>
                        {itemMapByType[MarketplaceItemType.SOLUTION].map((solutionSummary) => (
                            <SolutionBox
                                key={solutionSummary.templateName}
                                wideMode={wideMode}
                                exampleSolution={solutionSummary}
                            />
                        ))}
                    </CategoryWrapper>
                </>
            )}

            {/* Not showing free trial on library for now. */}
            {/* {discoverMode && <FreeTrialBanner />}*/}

            {itemMapByType[MarketplaceItemType.MODULE].length > 0 && (
                <>
                    {!hideGroupsHeaders && (
                        <ItemTypeHeader>
                            <H4>{`${discoverMode ? 'Featured ' : ''}${utils.capitalize(
                                MarketplaceItemType.MODULE.toLowerCase(),
                            )}s`}</H4>

                            {discoverMode && (
                                <AllLink
                                    state="/"
                                    params={{ category: MarketplaceHomeCategories.MODULES_TEMPLATES }}
                                    onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.MODULES_TEMPLATES)}
                                    underline={LinkUnderline.ALWAYS}
                                    replace
                                >
                                    {`All ${utils.capitalize(MarketplaceItemType.MODULE.toLowerCase())}s`}
                                    <Spacer width={10} />
                                    <LinkChevron direction={ChevronDirection.RIGHT} />
                                </AllLink>
                            )}
                        </ItemTypeHeader>
                    )}

                    <CategoryWrapper>
                        {itemMapByType[MarketplaceItemType.MODULE].map((moduleSummary) => (
                            <ModuleBox
                                key={moduleSummary.templateName}
                                moduleSummary={moduleSummary}
                                exampleDataSourceSummaries={moduleSummary.suggestedDataSources}
                                wideMode={wideMode}
                            />
                        ))}
                    </CategoryWrapper>
                </>
            )}

            {itemMapByType[MarketplaceItemType.DATA_SOURCE].length > 0 && (
                <>
                    {!hideGroupsHeaders && (
                        <ItemTypeHeader>
                            <H4>{`${discoverMode ? 'Featured ' : ''}${MarketplaceHomeCategories.DATA_SOURCES}`}</H4>
                            {discoverMode && (
                                <AllLink
                                    state="/"
                                    params={{ category: MarketplaceHomeCategories.DATA_SOURCES }}
                                    onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.DATA_SOURCES)}
                                    underline={LinkUnderline.ALWAYS}
                                    replace
                                >
                                    All {MarketplaceHomeCategories.DATA_SOURCES}
                                    <Spacer width={10} />
                                    <LinkChevron direction={ChevronDirection.RIGHT} />
                                </AllLink>
                            )}
                        </ItemTypeHeader>
                    )}
                    <CategoryWrapper>
                        {itemMapByType[MarketplaceItemType.DATA_SOURCE].map((dataSourceSummary) => (
                            <DatasourceBoxStyled
                                key={dataSourceSummary.templateName}
                                iconUrl={dataSourceSummary.iconUrl}
                                templateName={dataSourceSummary.templateName}
                                title={dataSourceSummary.title}
                                wideMode={wideMode}
                            />
                        ))}
                    </CategoryWrapper>
                </>
            )}

            {itemMapByType[MarketplaceItemType.PAGE].length > 0 && (
                <>
                    {!hideGroupsHeaders && (
                        <ItemTypeHeader>
                            <H4>{`${discoverMode ? 'Featured ' : ''} App pages`}</H4>

                            {discoverMode && (
                                <AllLink
                                    state="/"
                                    params={{ category: MarketplaceHomeCategories.APP_PAGES }}
                                    onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.APP_PAGES)}
                                    underline={LinkUnderline.ALWAYS}
                                    replace
                                >
                                    All App pages
                                    <Spacer width={10} />
                                    <LinkChevron direction={ChevronDirection.RIGHT} />
                                </AllLink>
                            )}
                        </ItemTypeHeader>
                    )}

                    <CategoryWrapper>
                        {itemMapByType[MarketplaceItemType.PAGE].map((pageSummary) => (
                            <PageBox key={pageSummary.templateName} pageSummary={pageSummary} wideMode={wideMode} />
                        ))}
                    </CategoryWrapper>
                </>
            )}

            <Spacer height={16} />
        </>
    );
};

export default MarketplaceItemsDiscoveryGrid;
