import type { GetStaticProps, NextPage } from 'next';
import React from 'react';

import Home from '../components/Home/Home';
import type DataSourceSummaryForHome from '../types/DataSourceSummaryForHome';
import type ModuleSummaryForHome from '../types/ModuleSummaryForHome';
import getDataSourceTemplateNameToMarketplaceItemRecord from '../utils/getDataSourceTemplateNameToMarketplaceItemRecord';
import getUsedDataSourcesTemplateNames from '../utils/getUsedDataSourcesTemplateNames';
import readAllMarketplaceItems from '../utils/readAllMarketplaceItems';

import type { ExampleSolutionSummary, PageSummaryForHome } from '@tonkean/marketplace-item-view';
import { getSolutionMarketplaceItemSummary } from '@tonkean/marketplace-item-view';
import type { DataSourceMarketplaceItem } from '@tonkean/tonkean-entities';
import { MarketplaceItemType } from '@tonkean/tonkean-entities';

const Page: NextPage<Props> = ({ solutionSummaries, dataSourceSummaries, moduleSummaries, pageSummaries }) => {
    return (
        <Home
            solutionSummaries={solutionSummaries}
            dataSourceSummaries={dataSourceSummaries}
            moduleSummaries={moduleSummaries}
            pageSummaries={pageSummaries}
        />
    );
};

export default Page;

export interface Props {
    solutionSummaries: ExampleSolutionSummary[];
    dataSourceSummaries: DataSourceSummaryForHome[];
    moduleSummaries: ModuleSummaryForHome[];
    pageSummaries: PageSummaryForHome[];
}

export const getStaticProps: GetStaticProps<Props> = async () => {
    const [
        allSolutionMarketplaceItems,
        allDataSourcesMarketplaceItems,
        allModuleMarketplaceItems,
        allPageMarketplaceItems,
    ] = await Promise.all([
        readAllMarketplaceItems(MarketplaceItemType.SOLUTION),
        readAllMarketplaceItems(MarketplaceItemType.DATA_SOURCE),
        readAllMarketplaceItems(MarketplaceItemType.MODULE),
        readAllMarketplaceItems(MarketplaceItemType.PAGE),
    ]);

    const solutionSummaries: ExampleSolutionSummary[] = allSolutionMarketplaceItems.map((solution) =>
        getSolutionMarketplaceItemSummary(solution),
    );
    const dataSourceSummaries = allDataSourcesMarketplaceItems.map((dataSource) => ({
        title: dataSource.title,
        iconUrl: dataSource.iconUrl,
        templateName: dataSource.templateName,
        categories: dataSource.categories || [],
        updated: dataSource.updated,
    }));
    const templateNameToDataSource = getDataSourceTemplateNameToMarketplaceItemRecord(allDataSourcesMarketplaceItems);

    const moduleSummaries = allModuleMarketplaceItems.map((module) => ({
        title: module.title,
        templateName: module.templateName,
        categories: module.categories || [],
        updated: module.updated,
        previewSummary: module.previewSummary || '',
        suggestedDataSources: getUsedDataSourcesTemplateNames(module.configuration.suggestedDatasources)
            .map((dataSourceTemplateName) => templateNameToDataSource[dataSourceTemplateName])
            .filter((dataSource): dataSource is DataSourceMarketplaceItem => !!dataSource)
            .map((dataSource) => ({
                title: dataSource.title,
                iconUrl: dataSource.iconUrl,
                templateName: dataSource.templateName,
            })),
    }));

    const pageSummaries = allPageMarketplaceItems.map((page) => ({
        title: page.title,
        templateName: page.templateName,
        categories: page.categories || [],
        updated: page.updated,
        previewSummary: page.previewSummary || '',
        image: page.images?.[0],
    }));

    return {
        props: {
            solutionSummaries,
            dataSourceSummaries,
            moduleSummaries,
            pageSummaries,
        },
    };
};
