import Router from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { StylesConfig } from 'react-select';
import styled, { css } from 'styled-components';

import MarketplaceItemsDiscoveryGrid from './MarketplaceItemsDiscoveryGrid';
import { ReactComponent as DiscoverIcon } from '../../assets/icons/discover.svg';
import { ReactComponent as GroupIcon } from '../../assets/icons/group.svg';
import { ReactComponent as HomeHeaderIcon } from '../../assets/icons/home-header.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/icons/integration.svg';
import { ReactComponent as ModuleIcon } from '../../assets/icons/module.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as SolutionIcon } from '../../assets/icons/solution.svg';
import { ReactComponent as TonkeanLogo } from '../../assets/icons/tonkean-logo-full.svg';
import type DataSourceSummaryForHome from '../../types/DataSourceSummaryForHome';
import type ModuleSummaryForHome from '../../types/ModuleSummaryForHome';
import LibraryHead from '../LibraryHead';

import type { SimpleSelectSingleOption } from '@tonkean/infrastructure';
import { Chevron } from '@tonkean/infrastructure';
import {
    Breakpoint,
    H1,
    H4,
    IconRadioGroup,
    IconRadioGroupButton,
    LayoutGridElements,
    SearchBox,
    SimpleSelect,
    Spacer,
    StateMessage,
    useDebouncedState,
    widthByLayoutGridColumns,
} from '@tonkean/infrastructure';
import type { ExampleSolutionSummary, PageSummaryForHome } from '@tonkean/marketplace-item-view';
import { MarketplaceHomeCategories } from '@tonkean/marketplace-item-view';
import { Clickable } from '@tonkean/tui-buttons/Clickable';
import { ClickableLink } from '@tonkean/tui-buttons/Link';
import { Theme } from '@tonkean/tui-theme';
import utils from '@tonkean/utils';

const Page = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;

    display: flex;
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    background-color: ${Theme.colors.brightestGray};
`;

const HomeHeaderIconStyled = styled(HomeHeaderIcon)`
    flex-shrink: 0;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        position: absolute;
        opacity: 0.2;
    }
`;

const Gutter = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;

    @media (max-width: ${Breakpoint.SMALL_1366}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 1;
    }

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }

    ${widthByLayoutGridColumns};
`;

const HeaderGutter = styled(Gutter)`
    flex-shrink: 0;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        display: none;
    }
`;

const SidePane = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 2;
    ${widthByLayoutGridColumns};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }

    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const HeaderContainer = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: ${Theme.current.palette.mainColors.basicBackground};
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
`;

const MainHeaderContainer = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    display: flex;
    justify-content: center;
    width: 100vw;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
    position: absolute;
    backdrop-filter: blur(44px);
`;

const Header = styled.div`
    ${widthByLayoutGridColumns};

    display: flex;
    font-family: Roboto;
    gap: 24px;
    position: relative;
`;

const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 10px 0;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        z-index: 1;
        padding: 16px 0;
        align-items: center;
        flex-shrink: unset;
        text-align: center;
    }
`;

const HeaderContentOnRight = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 12px 0;
    align-items: center;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        z-index: 1;
        padding: 16px 0;
        align-items: center;
        flex-shrink: unset;
        text-align: center;
    }
`;

const Content = styled.div`
    ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    flex-grow: 1;
    ${widthByLayoutGridColumns};

    & > * {
        height: 100%;
        padding-top: 24px;
    }

    display: flex;
    flex-direction: row;
    gap: 24px;
    position: relative;
`;

const ScrollTopGradient = styled.div`
    position: absolute;
    top: 0px;
    height: 16px;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(248, 248, 248, 1) 0%, rgba(248, 248, 248, 0.9) 20%, rgba(0, 0, 0, 0) 100%);
    padding: unset;
`;

const MainContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

const MainContentGutterContainer = styled.div`
    display: flex;
    gap: 24px;
`;

const MainContent = styled.div<{ searchMode: boolean }>`
    ${({ searchMode }) =>
        searchMode
            ? css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 10;
                  }
              `
            : css`
                  ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 6;

                  @media (max-width: ${Breakpoint.SMALL_1366}px) {
                      ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 8;
                  }
              `};

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 12;
    }

    @media (max-width: ${Breakpoint.XSMALL_640}px) {
        ${LayoutGridElements.ELEMENT_COLUMNS_COUNT}: 4;
    }

    ${widthByLayoutGridColumns};
`;

const SidePaneText = styled(ClickableLink)<{ disableMargin?: boolean }>`
    display: flex;
    align-items: center;
    ${({ disableMargin = false }) =>
        !disableMargin &&
        css`
            margin-left: 31px;
        `}

    font-family: Roboto;
    font-weight: 400;
    line-height: 16px;
`;

const SidePaneTextMain = styled(SidePaneText)`
    ${({ disableMargin = false }) =>
        !disableMargin &&
        css`
            margin-left: 7px;
        `}

    font-weight: 700;
`;

const DiscoverPaneTextMain = styled(SidePaneTextMain)`
    font-weight: 500;
`;

const StyledIconRadioGroupButton = styled(IconRadioGroupButton)`
    & > * {
        line-height: 0;
    }
`;

const StyledIconRadioGroupButtonWithIcon = styled(StyledIconRadioGroupButton)`
    margin-bottom: 14px !important;
`;

const ContentHeader = styled(H1)`
    padding-bottom: 16px;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const ContentHeaderSelector = styled(SimpleSelect)`
    display: none;
    width: 100%;
    max-width: 500px;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: unset;
    }
`;

const SearchBoxHeader = styled(SearchBox)`
    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: none;
    }
`;

const SearchBoxMobile = styled(SearchBox)`
    display: none;
    flex-shrink: 0;
    align-self: center;
    margin-bottom: 24px;
    height: 36px;

    @media (max-width: ${Breakpoint.MID_XSMALL_768}px) {
        display: unset;
    }
`;

const EmptyStateMessage = styled(StateMessage)`
    margin-top: 64px;
    height: unset;
`;

const FreeTrialButton = styled(Clickable)`
    background: ${Theme.colors.promotion};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-family: 'Lato';
    font-weight: 700;
    padding: 8px;
    padding-inline: 0;
    font-size: 14px;
    width: 160px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background: #4340aa;
        color: white;
    }
`;

const TonkeanHomeLink = styled.a`
    width: fit-content;
`;
const LinkChevron = styled(Chevron)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;

    svg > path {
        stroke: white;
    }
`;

interface Props {
    solutionSummaries: ExampleSolutionSummary[];
    dataSourceSummaries: DataSourceSummaryForHome[];
    moduleSummaries: ModuleSummaryForHome[];
    pageSummaries: PageSummaryForHome[];
}

const Home: React.FC<Props> = ({ solutionSummaries, dataSourceSummaries, moduleSummaries, pageSummaries }) => {
    const [selectedCategory, setSelectedCategory] = useState(MarketplaceHomeCategories.DISCOVER.toString());
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [searchTerm, setSearchTerm] = useDebouncedState<string>('', setDebouncedSearchTerm);

    const itemCategories = useMemo(() => {
        return ['Legal', 'Sales', 'Customer', 'Finance', 'IT', 'HR'];
    }, []);

    const [filteredSolutions, filteredModules, filteredDataSources, filteredPages]: [
        solutions: ExampleSolutionSummary[],
        modules: ModuleSummaryForHome[],
        dataSources: DataSourceSummaryForHome[],
        pages: PageSummaryForHome[],
    ] = useMemo(() => {
        if (searchTerm) {
            return [
                solutionSummaries.filter((solutionSummary) => utils.flexIncludes(solutionSummary.title, searchTerm)),
                moduleSummaries.filter((moduleSummary) => utils.flexIncludes(moduleSummary.title, searchTerm)),
                dataSourceSummaries.filter((dataSourceSummary) =>
                    utils.flexIncludes(dataSourceSummary.title, searchTerm),
                ),
                pageSummaries.filter((pageSummary) => utils.flexIncludes(pageSummary.title, searchTerm)),
            ];
        }

        if (selectedCategory === MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS) {
            return [solutionSummaries, [], [], []];
        }

        if (selectedCategory === MarketplaceHomeCategories.MODULES_TEMPLATES) {
            return [[], moduleSummaries, [], []];
        }

        if (selectedCategory === MarketplaceHomeCategories.DATA_SOURCES) {
            return [[], [], dataSourceSummaries, []];
        }

        if (selectedCategory === MarketplaceHomeCategories.APP_PAGES) {
            return [[], [], [], pageSummaries];
        }

        if (selectedCategory === MarketplaceHomeCategories.DISCOVER) {
            return [solutionSummaries, moduleSummaries, dataSourceSummaries, pageSummaries];
        }

        return [
            solutionSummaries.filter((solutionSummary) => solutionSummary.categories.includes(selectedCategory)),
            moduleSummaries.filter((moduleSummary) => (moduleSummary.categories || []).includes(selectedCategory)),
            dataSourceSummaries.filter((dataSourceSummary) =>
                (dataSourceSummary.categories || []).includes(selectedCategory),
            ),
            pageSummaries.filter((pageSummary) => (pageSummary.categories || []).includes(selectedCategory)),
        ];
    }, [searchTerm, selectedCategory, solutionSummaries, moduleSummaries, dataSourceSummaries, pageSummaries]);

    const updateQuery = useCallback((category?: string, search?: string) => {
        const query = {};
        if (category) {
            query['category'] = category;
        }
        if (search) {
            query['search'] = search;
        }

        Router.replace({
            pathname: '/',
            query,
        });
    }, []);

    // Check url query and update state on page load
    useEffect(() => {
        // We manually parse the path instead of using query because it's empty on first page load
        const query = {};
        const queryParams: string[] = Router.asPath.split('/?')[1]?.split('&') || [];
        queryParams.forEach((param) => {
            const paramParsed = param.split('=');
            if (paramParsed[0] && paramParsed[1]) {
                query[paramParsed[0]] = decodeURIComponent(paramParsed[1] || '')?.replaceAll('+', ' ');
            }
        });

        const category = query['category'];
        const search = query['search'];

        if (category) {
            if (itemCategories.includes(category) || Object.values(MarketplaceHomeCategories).includes(category)) {
                onCategoryChanged(category);
            } else {
                onCategoryChanged(MarketplaceHomeCategories.DISCOVER);
            }
        } else if (search) {
            setSearchTerm(search);
        }
        // Empty deps array to make sure only happens on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCategoryChanged = useCallback(
        (value: string) => {
            setSelectedCategory(value);

            setSearchTerm('');
            updateQuery(value, undefined);
        },
        [setSearchTerm, updateQuery],
    );

    // When the free text search term is updated change the url accordingly
    useEffect(() => {
        if (debouncedSearchTerm) {
            updateQuery(undefined, debouncedSearchTerm);
        }
        if (!debouncedSearchTerm) {
            updateQuery(selectedCategory, undefined);
        }
    }, [debouncedSearchTerm, selectedCategory, updateQuery]);

    const options: SimpleSelectSingleOption<string>[] = useMemo(() => {
        return [
            {
                value: MarketplaceHomeCategories.DISCOVER,
                label: (
                    <DiscoverPaneTextMain disableColoring disableMargin>
                        {MarketplaceHomeCategories.DISCOVER}
                    </DiscoverPaneTextMain>
                ),
                icon: <DiscoverIcon />,
            },
            // Temporarily removing the categories from the homepage.
            // ...[...itemCategories.values()].map((category) => ({
            //     value: category,
            //     icon: <Spacer height={14} width={14} />,
            //     label: (
            //         <DiscoverPaneTextMain disableColoring disableMargin>
            //             {category}
            //         </DiscoverPaneTextMain>
            //     ),
            // })),
            {
                value: MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS,
                label: (
                    <DiscoverPaneTextMain disableColoring disableMargin>
                        {MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS}
                    </DiscoverPaneTextMain>
                ),
                icon: <SolutionIcon />,
            },
            {
                value: MarketplaceHomeCategories.MODULES_TEMPLATES,
                label: (
                    <DiscoverPaneTextMain disableColoring disableMargin>
                        {MarketplaceHomeCategories.MODULES_TEMPLATES}
                    </DiscoverPaneTextMain>
                ),
                icon: <ModuleIcon />,
            },
            {
                value: MarketplaceHomeCategories.DATA_SOURCES,
                label: (
                    <DiscoverPaneTextMain disableColoring disableMargin>
                        {MarketplaceHomeCategories.DATA_SOURCES}
                    </DiscoverPaneTextMain>
                ),
                icon: <IntegrationIcon />,
            },
            {
                value: MarketplaceHomeCategories.APP_PAGES,
                label: (
                    <DiscoverPaneTextMain disableColoring disableMargin>
                        {MarketplaceHomeCategories.APP_PAGES}
                    </DiscoverPaneTextMain>
                ),
                icon: <GroupIcon />,
            },
        ];
    }, []);

    // Should show the discovery grid
    const showDiscoveryGrid = useMemo(() => {
        return [...filteredSolutions, ...filteredModules, ...filteredDataSources, ...filteredPages].length > 0;
    }, [filteredDataSources, filteredModules, filteredPages, filteredSolutions]);

    const styles: StylesConfig<SimpleSelectSingleOption<string>> = {
        singleValue: (provided) => ({
            ...provided,
            color: Theme.colors.gray_700,
        }),
    };

    return (
        <Page>
            <LibraryHead content={[selectedCategory]} />
            <MainHeaderContainer>
                <Header>
                    <HeaderGutter />

                    <HeaderContent>
                        <TonkeanHomeLink href="https://tonkean.com">
                            <TonkeanLogo height={36} />
                        </TonkeanHomeLink>
                    </HeaderContent>

                    {/* Not showing free trial on library for now. */}

                    {/* <HeaderContentOnRight>*/}
                    {/*    <FreeTrialButton*/}
                    {/*        href="https://tracks.tonkean.com/login"*/}
                    {/*        data-analytics-event="14-days-trial-button-click"*/}
                    {/*    >*/}
                    {/*        Free 7-day trial*/}
                    {/*        <LinkChevron direction={ChevronDirection.RIGHT} />*/}
                    {/*    </FreeTrialButton>*/}
                    {/* </HeaderContentOnRight>*/}
                </Header>
            </MainHeaderContainer>
            <HeaderContainer>
                <Header>
                    <HeaderGutter />
                    <HeaderContent>
                        <Spacer height={80} />
                        <H1>Component Library</H1>
                        <Spacer height={8} />
                        <H4>Add ready-to-go components from our predefined blueprints and templates.</H4>
                        <Spacer height={16} />
                        <SearchBoxHeader
                            data-automation="home-search-item"
                            placeholder="Search solution blueprints, module templates, data sources or by category"
                            onChange={(event) => setSearchTerm(event.target.value)}
                            value={searchTerm}
                            $maxWidth="500px"
                        />
                        <ContentHeaderSelector
                            value={selectedCategory}
                            options={options}
                            onChange={onCategoryChanged}
                            isSearchable={false}
                            styles={styles}
                        />
                    </HeaderContent>
                    <HomeHeaderIconStyled />
                    <HeaderGutter />
                </Header>
            </HeaderContainer>
            <Content>
                <ScrollTopGradient />
                <Gutter />
                {!searchTerm && (
                    <SidePane>
                        <IconRadioGroup name="Catagories" value={selectedCategory} onChange={onCategoryChanged}>
                            <StyledIconRadioGroupButtonWithIcon
                                value={MarketplaceHomeCategories.DISCOVER}
                                icon={<DiscoverIcon />}
                                label={
                                    <DiscoverPaneTextMain
                                        params={{ category: MarketplaceHomeCategories.DISCOVER }}
                                        onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.DISCOVER)}
                                        replace
                                        disableColoring
                                    >
                                        {MarketplaceHomeCategories.DISCOVER}
                                    </DiscoverPaneTextMain>
                                }
                            />

                            {/* Temporarily hiding the categories from homepage */}
                            {/* <OperationTypeList>*/}
                            {/*    {itemCategories.map((category) => (*/}
                            {/*        <StyledIconRadioGroupButtonWithoutIcon*/}
                            {/*            key={category}*/}
                            {/*            value={category}*/}
                            {/*            label={*/}
                            {/*                <SidePaneText*/}
                            {/*                    params={{ category }}*/}
                            {/*                    onClick={(e) => onCategoryChanged(category)}*/}
                            {/*                    replace*/}
                            {/*                    disableColoring*/}
                            {/*                >*/}
                            {/*                    {category}*/}
                            {/*                </SidePaneText>*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    ))}*/}
                            {/* </OperationTypeList>*/}

                            <StyledIconRadioGroupButtonWithIcon
                                value={MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS}
                                icon={<SolutionIcon />}
                                label={
                                    <SidePaneTextMain
                                        params={{ category: MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS }}
                                        onClick={(e) =>
                                            onCategoryChanged(MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS)
                                        }
                                        replace
                                        disableColoring
                                    >
                                        {MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS}
                                    </SidePaneTextMain>
                                }
                            />
                            <StyledIconRadioGroupButtonWithIcon
                                value={MarketplaceHomeCategories.MODULES_TEMPLATES}
                                icon={<ModuleIcon />}
                                label={
                                    <SidePaneTextMain
                                        params={{ category: MarketplaceHomeCategories.MODULES_TEMPLATES }}
                                        onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.MODULES_TEMPLATES)}
                                        replace
                                        disableColoring
                                    >
                                        {MarketplaceHomeCategories.MODULES_TEMPLATES}
                                    </SidePaneTextMain>
                                }
                            />
                            <StyledIconRadioGroupButtonWithIcon
                                value={MarketplaceHomeCategories.DATA_SOURCES}
                                icon={<IntegrationIcon />}
                                label={
                                    <SidePaneTextMain
                                        params={{ category: MarketplaceHomeCategories.DATA_SOURCES }}
                                        onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.DATA_SOURCES)}
                                        replace
                                        disableColoring
                                    >
                                        {MarketplaceHomeCategories.DATA_SOURCES}
                                    </SidePaneTextMain>
                                }
                            />
                            {pageSummaries.length > 0 && (
                                <StyledIconRadioGroupButtonWithIcon
                                    value={MarketplaceHomeCategories.APP_PAGES}
                                    icon={<GroupIcon />}
                                    label={
                                        <SidePaneTextMain
                                            params={{ category: MarketplaceHomeCategories.APP_PAGES }}
                                            onClick={(e) => onCategoryChanged(MarketplaceHomeCategories.APP_PAGES)}
                                            replace
                                            disableColoring
                                        >
                                            {MarketplaceHomeCategories.APP_PAGES}
                                        </SidePaneTextMain>
                                    }
                                />
                            )}
                        </IconRadioGroup>
                    </SidePane>
                )}

                <MainContentWrapper>
                    <SearchBoxMobile
                        placeholder="Search items across the library"
                        onChange={(event) => setSearchTerm(event.target.value)}
                        value={searchTerm}
                        $maxWidth="500px"
                    />
                    <ContentHeader>{searchTerm ? 'Search Results' : selectedCategory}</ContentHeader>
                    <MainContentGutterContainer>
                        <MainContent searchMode={!!searchTerm}>
                            {showDiscoveryGrid ? (
                                <MarketplaceItemsDiscoveryGrid
                                    discoverMode={
                                        selectedCategory === MarketplaceHomeCategories.DISCOVER && !searchTerm
                                    }
                                    wideMode={!!searchTerm}
                                    hideGroupsHeaders={
                                        selectedCategory === MarketplaceHomeCategories.DATA_SOURCES ||
                                        selectedCategory === MarketplaceHomeCategories.MODULES_TEMPLATES ||
                                        selectedCategory === MarketplaceHomeCategories.SOLUTIONS_BLUEPRINTS ||
                                        selectedCategory === MarketplaceHomeCategories.APP_PAGES
                                    }
                                    solutionSummaries={filteredSolutions}
                                    moduleSummaries={filteredModules}
                                    dataSourceSummaries={filteredDataSources}
                                    pageSummaries={filteredPages}
                                    onCategoryChanged={onCategoryChanged}
                                />
                            ) : (
                                <EmptyStateMessage
                                    icon={<SearchIcon />}
                                    title={`No Results for ‘${searchTerm || selectedCategory}’`}
                                >
                                    Try to refine your search
                                </EmptyStateMessage>
                            )}
                        </MainContent>
                        <Gutter />
                    </MainContentGutterContainer>
                </MainContentWrapper>
            </Content>
        </Page>
    );
};

export default Home;
